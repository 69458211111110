.Row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); */
    padding: 10px;
    background-color: rgb(247, 244, 244);
    width: 60%;
    margin-left: auto;
    margin-right: auto;

}

.RowColourSubmitted {

    border-left: 5px solid green;

}
.RowColourOpen {
    border-left: 5px solid orange;
}


.RowItem {
    text-align: left;
    width: 80%;
    margin: auto;
    background-color: inherit;
}


.RowInput {
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.5rem;;
}

.RowInput:disabled{
    background-color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ContainerDateButtons {
    /* display: flex;
    align-items: center;
    justify-content: center; */

    position: sticky;
    top: 0;
    background-color: white;
    width: auto;
    padding-top: 10px;

}

.DateTitle{
    display: inline;
    font-size: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    font-weight: 500;
}

.EntrySize {
    display: inline;
    font-size: 1.25rem;
    font-weight:  500;
}

.Bottom {
    margin-top: 0.5rem;
}

.RowLabel {
    /* margin-right: 85%; */
    margin-bottom: 0px;
    text-align: left;
    padding-left: 5px;
    font-weight: 600;
}

/* .ContainerSwiper {

    min-height: 50vh;
    max-height: 50vh;
    overflow: auto;
} */




.ContainerComment {
    margin-top: 2px;
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

.ModalTitle {
    display:block;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1px;
}


.CommentTitleModal {

    text-align: left;
    margin-top: 10px;
    margin-bottom: 0;
}

.Content {
    margin: auto;
    width: 80%;
    padding-bottom: 60px;
}

.RoundButton{
    border-radius: 50%;
    margin: 15px;
    border-color:  rgb(48, 65, 80);
    color:rgb(48, 65, 80);

}

.RoundButton:hover {

    background-color:  rgb(48, 65, 80);
    color: white;
    border-color: rgb(48, 65, 80);

}

.RoundButton:active {

    background-color:  rgb(48, 65, 80) !important;
    color: white;
    border-color: rgb(48, 65, 80);
    box-shadow: none !important;

}


.RoundButton:focus {

    background-color:  rgb(48, 65, 80);
    color: white;
    border-color: rgb(48, 65, 80);
    box-shadow: none !important;
    border-color: rgb(48, 65, 80);
    border-width: 2px

}



.ButtonHolder{
    position: fixed;
    bottom: 0;
    background-color: white;
    left:0;
    right: 0;
    /* max-height: 100%; */
}

.BtnFormSubmit{

    min-width: 33%;
    font-weight: 500;
    margin: 5px;
    margin-bottom: 15px;
}


.BtnRemoveRow{

    float: right;
}



@media (max-width: 550px){
    .Row{
        width: 100%;
    }
}
.ForecastStyle{
    color: orange;
}
