.Links {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 25px;
    margin: auto;
    padding: 0 50px;
    text-align: left;
    /* margin: auto; */
}

.Link {
    position: relative;
    height: 150px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid grey;
    text-align: center;
}

.LinkTitle {
    color: darkslategray;
    text-decoration: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 100%;
}

.LinkTitle:hover {
    text-transform: none;
}

.Link:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}


@media only screen and (max-width: 480px) {
    .Links {
        justify-content: center;
    }
  }