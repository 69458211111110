.ContainerDateButtons {
   composes: ContainerDateButtons from "../DailyTSView/DailyTSView.module.css"

}

/* .ContainerDateButtons button {
  margin-left: 5px;
} */

.DateTitle {
        display: inline-block;
        text-overflow: ellipsis;
        text-align: center;
        width: 140px;
        composes: DateTitle from "../DailyTSView/DailyTSView.module.css";

}

.RoundButton {
  composes: RoundButton from "../DailyTSView/DailyTSView.module.css";

}

.DateNavBtn {

  background-color: white; /* Green */
  outline: none;
  color: rgb(48, 65, 80);
  font-size: 1.5rem;
  font-weight: 500;
  border-color: rgb(48, 65, 80);
  border-width: 1.5px;
  border-radius: 15%;
}

.DateNavBtn:hover  {

  background-color: rgb(48, 65, 80);
  /* outline: none; */
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  border-color: rgb(48, 65, 80);
  border-width: 1.5px;
}

.DateNavBtn:active {

  background-color: rgb(48, 65, 80);
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  border-color: rgb(48, 65, 80);
  border-width: 1.5px;

}

.Table{
  /* width: auto;
  display: table;
  overflow: auto;
  align-self: center; */
  table-layout: fixed;

}
.Table td{
  width: auto;
}

.InputTH {
  width: 70px;
}

.SelectTH{
  width: 110px;
}

.Table input {

  width: 100%;
  height: 100%;
  vertical-align: middle;
  font-size: inherit;
  margin-top: 2px;
}

.Table select{
  width: 100%;
  align-items: center;
  font-size: inherit;
  height: 100%;
  /* font-size: 1rem; */
  -webkit-appearance:none;
}


.CommentButton {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(48, 65, 80);
    background-color: white;
    color:  rgb(48, 65, 80);
    outline: rgb(48, 65, 80);
}

/* .CommentButton:visited {
  color: white;
  background-color:  rgb(48, 65, 80) !important;
  border-color:  rgb(48, 65, 80) !important;
  box-shadow: none !important;
  border: 2px solid rgb(48, 65, 80);

} */
.CommentButton:focus {
    color: white;
    background-color:  rgb(48, 65, 80) !important;
    box-shadow: none;

}
.CommentButton:hover {
  color: white;
  background-color:  rgb(48, 65, 80);
  border-color: rgb(48, 65, 80) !important;
  border-width: 2px;
  border-color: rgb(48, 65, 80);

}


.TableDiv{
  overflow: auto;
  width: 100%;
}

.BtnFormSubmit{

  min-width: 33%;
  font-weight: 500;
  margin: 5px;
  margin-bottom: 15px;
}
/* 
td{
  height: 100%;
  width: 100%;
  font-size: 1.4rem;
} */

.CommentLabel {
  display: block;
  text-align: left;
  font-size: 1rem;
  padding-top: 5px;
  font-weight: 600;

}

.CommentInputText {
  
  margin-bottom: 10px;
  font-size: 1.25rem;


}

.ModalDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;

}
.InnerModalDiv {
  width: 100%;
  justify-content: center;
}
.InnerModalDivButton {
  display: inline-block;
  background-color: white;
  width: 100%;
  justify-content: center;
  position: sticky;
  bottom: 0;
  /* margin-bottom: 10px; */
}

.ModalSaveButton{
  float: right;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */

}

.TdTotal{
  text-align: right;
}

.DeleteButton{

  /* float: right;
  position: relative;
  left:380px;
  bottom: 39px;
  z-index: 5; */
  
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}

.TableInput {
  text-align: right;
}

.ColTotal{
  margin-right: 3px;
}

.ModalDiv{
  /* display: grid;
  overflow: auto */
  /* display: inline-grid; */
}

.ForecastStyle{
  color: orange;
}

.SubmittedData{

  background-color: rgba(73, 199, 111, 0.199);
}

.OpenedData{
  background-color: rgba(255, 166, 0, 0.13);
}

.SelectDisabled:disabled{
  background-color: inherit;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.SelectDisabled{
  width: auto;
}
/* 
@media (max-width: 1000px){
  .SelectTH{
    width: 65px;
  }
} */