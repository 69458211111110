.Requests {
    position: relative;
    box-sizing: border-box;
    text-align: left;
    /* font-size: 1rem; */
    max-width: 90%;
    /* border: 1px solid white; */
    margin: auto;
    background-color: white;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px #504f4f;
    padding: 25px;
    box-sizing: border-box;
}

@media only screen and (min-width: 550px) {
    .Requests {
        max-width: 1000px;
    }
}

/* .Row {
    text-align: left;
    /* margin: 2px auto; */
/*} */
.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.HeaderRow {
    display: flex;
    flex-direction: row;
    /* gap: 150px; */
    align-items: center;
    justify-content: space-between;
}
.HeaderRowRight {
    display: flex;
    flex-direction: row;
    /* gap: 150px; */
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: -5px;
    left: 0px;
    gap: 10px;
}
.RowLabel {
    font-size: 1rem;
    color: grey;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 5px;
    font-weight: 600;
}