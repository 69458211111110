.WorkLocationsInputs {
    margin: auto;
    width: 100%;
}

.InputTH {
    width: 70px;
}

.SelectTH{
    width: 110px;
}

.HeaderButtonRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 5px;
    padding: 5px 0 15px 0;
}

.ChildButton {
    width: 49%;
}

.CommentButton {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(48, 65, 80);
    background-color: white;
    color:  rgb(48, 65, 80);
    outline: rgb(48, 65, 80);
}

.CommentModal {
    text-align: left;
}