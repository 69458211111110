.ApprovalsCentre {
    position: relative;
    /* box-sizing: border-box; */
    text-align: left;
    /* font-size: 1rem; */
    max-width: 90%;
    /* border: 1px solid white; */
    margin: auto;
    background-color: white;
    /* border-radius: 5px; */
    /* box-shadow: 5px 5px 10px #504f4f; */
    /* padding: 25px; */
    /* box-sizing: border-box; */
}

@media only screen and (min-width: 550px) {
    .ApprovalsCentre {
        max-width: 1250px;
    }
}

.HeaderButtonRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 5px;
    padding: 5px 0 15px 0;
}