.Timesheet {

    margin: auto;
    width: 100%;
    
}

.DateViewButton {

    min-width: 65px;
    font-size: 1.25rem;
    font-weight: 500;
    margin-right: 3px;
    margin-left: 3px;
    border: 2px solid rgb(48, 65, 80);
    background-color: white;
    color:  rgb(48, 65, 80);
    outline: rgb(48, 65, 80);

}
.DateViewButton:visited{
    color: white;
    background-color:  rgb(48, 65, 80) !important;
    border-color:  rgb(48, 65, 80) !important;
    box-shadow: none !important;
    border: 2px solid rgb(48, 65, 80);
}

.DateViewButton:focus {
    color: white;
    background-color:  rgb(48, 65, 80) !important;
    box-shadow: none;
}

.DateViewButton:hover {
    color: white;
    background-color:  rgb(48, 65, 80);
    border-color: rgb(48, 65, 80) !important;
    border-width: 2px;
    border-color: rgb(48, 65, 80);

}
/* .DateViewButton:target {
    color: white;
    background-color:  rgb(48, 65, 80) !important;
    box-shadow: none !important;
    border-color:  rgb(48, 65, 80) !important;
;
} */

.DateViewButton:active {
    color: white;
    background-color:  rgb(48, 65, 80) !important;
    border-color:  rgb(48, 65, 80) !important;
    box-shadow: none !important;
    border: 2px solid rgb(48, 65, 80);
}


@media only screen and (min-width: 550px){
    .Timesheet {

        margin: auto;
        max-width: 70%;
    }
}