
.MuiTableCell-root .MuiTableCell-body .MuiTableCell-paddingNone{
    display: none;
}

.Button{
top: 135px;
position: absolute;
right: 400px;
z-index: 50;
}