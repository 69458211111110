.WorkLocations {
    margin: auto;
    width: 100%;
}

.ContainerDateButtons {
    position: sticky;
    top: 0;
    background-color: white;
    width: auto;
    padding-top: 10px;
}

.DateNavBtn {
    background-color: white; /* Green */
    outline: none;
    color: rgb(48, 65, 80);
    font-size: 1.5rem;
    font-weight: 500;
    border-color: rgb(48, 65, 80);
    border-width: 1.5px;
    border-radius: 15%;
}

.DateNavBtn:hover  {
    background-color: rgb(48, 65, 80);
    /* outline: none; */
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    border-color: rgb(48, 65, 80);
    border-width: 1.5px;
}

.DateNavBtn:active {
    background-color: rgb(48, 65, 80);
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    border-color: rgb(48, 65, 80);
    border-width: 1.5px;
}

.DateTitle {
    display: inline-block;
    text-overflow: ellipsis;
    text-align: center;
    width: 140px;
    display: inline;
    font-size: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    font-weight: 500;
}

.InputTH {
    width: 70px;
}

.SelectTH{
    width: 110px;
}

.TableDiv{
    overflow: auto;
    width: 100%;
}

.Legend{
    text-align: center;
    /* align-items: center; */
    /* width: 30%; */
}

.LegendFlex{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.LegendCaption{
    /* font-size: 12px; */
    padding-top: 8px;
}

.CommentButton {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(48, 65, 80);
    background-color: white;
    color:  rgb(48, 65, 80);
    outline: rgb(48, 65, 80);
}

.CommentModal {
    text-align: left;
}

@media only screen and (min-width: 500px){
    .WorkLocations {
        margin: auto;
        max-width: 90%;
    }
}