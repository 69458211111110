.Table {
    font-size: 1rem;
    /* table-layout:fixed; */
    width: 100%;
}


.SelectFilter {
    font-size: 1rem;
    padding: 0;
    height: 20px;
}

.Row {
    white-space: nowrap;
    /* white-space: normal; */
}
.HeaderButtonRowRight {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 5px;
}
.HeaderButtonRowLeft {
    position: absolute;
    padding-top: 8px;
    padding-left: 0px;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: -1.5px;
    color: rgb(75, 75, 75)
    /* top: 5px; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* gap: 5px; */
    /* padding-bottom: 5px; */
}