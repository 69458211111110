.ResourceAssign {
    position: relative;
    box-sizing: border-box;
    text-align: center;
    font-size: 2rem;
    max-width: 90%;
    /* border: 1px solid white; */
    margin: auto;
    background-color: white;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px #504f4f;
    padding: 25px;
    box-sizing: border-box;
}

@media only screen and (min-width: 550px) {
    .ResourceAssign {
        max-width: 350px;
    }
}

.Row {
    text-align: left;
    /* margin: 2px auto; */
}

.RowLabel {
    font-size: 1rem;
    color: grey;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 5px;
    font-weight: 600;
}